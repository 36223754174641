import SystemtModule from '@/store/SystemModule'
import { snakeCase } from 'lodash'
import moment from 'moment'
import { getModule } from 'vuex-module-decorators'
import { DateTime } from 'luxon'
import SelectOption from './SelectOption'

export const LINEAR_COMPANY_IDS = [
  '92467be6-bab3-4b6c-8de4-4bcb1c23960f',
  '95ab38bf-086b-4b9b-a1f1-1ea9cf729623',
  'decba4d6-a0e7-11ee-a2a8-060388677220',
]

export const StatusOptions: any = [
  new SelectOption('Active', 'active'),
  new SelectOption('Inactive', 'inactive'),
]

export const AdserverOptions: any = [
  new SelectOption('GAM', 'gam'),
  new SelectOption('Freewheel', 'freewheel'),
]

export const PaginationOptions: SelectOption[] = [
  new SelectOption('1', '1'),
  new SelectOption('3', '3'),
  new SelectOption('5', '5'),
  new SelectOption('10', '10'),
  new SelectOption('25', '25'),
  new SelectOption('50', '50'),
  new SelectOption('100', '100'),
]

export const PaginationOptionsAll: SelectOption[] = [
  ...PaginationOptions,
  new SelectOption('All (Resource intensive)', 'all'),
]

export const OrderTypes: any = [
  new SelectOption('Cash', 'cash'),
  new SelectOption('Trade', 'trade'),
]

export const MediaPlanProposalActions: any = [
  new SelectOption('New', 'New'),
  new SelectOption('Revised', 'Revised'),
]

export const DeviceOptions = [
  new SelectOption('Connected TV', 'ctv'),
  new SelectOption('Desktop', 'desktop'),
  new SelectOption('Mobile', 'mobile'),
]

export const VastVersions: any = [
  new SelectOption('4.2', '4.2'),
  new SelectOption('4.1', '4.1'),
  new SelectOption('4.0', '4.0'),
  new SelectOption('3.0', '3.0'),
  new SelectOption('2.0', '2.0'),
]

export const ImpressionModelOptions: any = [
  new SelectOption('CPM', 'cpm'),
  new SelectOption('Flat', 'flat'),
]

export const SchedulerModes: any = [
  new SelectOption('On/Off', 'on_off'),
  new SelectOption('Inventory Type', 'state'),
]

export const CreativeLength: any = [
  new SelectOption('15s', '15'),
  new SelectOption('30s', '30'),
  new SelectOption('60s', '60'),
  new SelectOption('75s', '75'),
  new SelectOption('90s', '90'),
]

export const OrderTypeOptions: any = [
  new SelectOption('Cash', 'cash'),
  new SelectOption('Trade', 'tarde'),
]

export const RedistributeImpressions: any = [
  new SelectOption('No', 'no'),
  new SelectOption('To Cash & Trade Items', 'all'),
  new SelectOption('To Cash Items', 'cash'),
  new SelectOption('To Trade Items', 'trade'),
]

export const TargetOptions: SelectOption[] = [
  new SelectOption('People (P)', 'P'),
  new SelectOption('Male (M)', 'M'),
  new SelectOption('Female (F)', 'F'),
  new SelectOption('Adults (AD)', 'AD'),
]

export const DaysOfWeek: SelectOption[] = [
  new SelectOption('Monday', 'monday'),
  new SelectOption('Tuesday', 'tuesday'),
  new SelectOption('Wednesday', 'wednesday'),
  new SelectOption('Thursday', 'thursday'),
  new SelectOption('Friday', 'friday'),
  new SelectOption('Saturday', 'saturday'),
  new SelectOption('Sunday', 'sunday'),
]

export const MediaPlanTypes: SelectOption[] = [
  new SelectOption('Default', 'default'),
  new SelectOption('Media Ocean', 'media_ocean'),
  new SelectOption('Strata', 'strata'),
]

export const AgeLowOptions: SelectOption[] = [
  new SelectOption('0', '0'),
  new SelectOption('2', '2'),
  new SelectOption('6', '6'),
  new SelectOption('12', '12'),
  new SelectOption('18', '18'),
  new SelectOption('21', '21'),
  new SelectOption('25', '25'),
  new SelectOption('35', '35'),
  new SelectOption('45', '45'),
  new SelectOption('50', '50'),
  new SelectOption('55', '55'),
  new SelectOption('65', '65'),
]

export const AgeHighOptions: SelectOption[] = [
  new SelectOption('5', '5'),
  new SelectOption('11', '11'),
  new SelectOption('17', '17'),
  new SelectOption('20', '20'),
  new SelectOption('24', '24'),
  new SelectOption('34', '34'),
  new SelectOption('44', '44'),
  new SelectOption('49', '49'),
  new SelectOption('54', '54'),
  new SelectOption('64', '64'),
  new SelectOption('99+', '+'),
]

export const MediaPlanViewColumns: SelectOption[] = [
  new SelectOption('Model', 'model'),
  new SelectOption('Net Rate', 'net_rate'),
  new SelectOption('Net Cost', 'net_cost'),
  new SelectOption('Gross Rate', 'gross_rate'),
  new SelectOption('Gross Cost', 'gross_cost'),
  new SelectOption('Max Avails', 'max_avails'),
]

export const MediaPlanViewColumnsLinear: SelectOption[] = [
  new SelectOption('Model', 'model'),
  new SelectOption('Net Rate', 'net_rate'),
  new SelectOption('Net Cost', 'net_cost'),
  new SelectOption('Gross Rate', 'gross_rate'),
  new SelectOption('Gross Cost', 'gross_cost'),
  new SelectOption('Max Avails', 'max_avails'),
  new SelectOption('GRPs', 'grps'),
]

export const InvoiceUploadMode: SelectOption[] = [
  new SelectOption('Invoice Manager (DS)', 'platform'),
  new SelectOption('FTP (OX)', 'ftp'),
]

export const Products: SelectOption[] = [
  new SelectOption('SSL', 'ssl'),
  new SelectOption('CCL', 'ccl'),
]

export const ProductsFull: SelectOption[] = [
  new SelectOption('SportStreamLive', 'ssl'),
  new SelectOption('CrowdConnectLive', 'ccl'),
]

export const InvoiceDeliveryMode: SelectOption[] = [
  new SelectOption('E-mail', 'email'),
  new SelectOption('Coupa', 'coupa'),
  new SelectOption('Basis', 'basis'),
  new SelectOption('None', 'none'),
]

export const AdserverTypes: SelectOption[] = [
  new SelectOption('Cast Iron Media AdServer', 'cim'),
  new SelectOption('Google Ad Manager', 'gam'),
]

export const InvoiceBillingTarget: SelectOption[] = [
  new SelectOption('Bill Agency', 'agency'),
  new SelectOption('Bill Station', 'station'),
  new SelectOption('Bill Advertiser', 'advertiser'),
]

export const CompanyTypes = [
  new SelectOption('Advertiser', 'advertiser'),
  new SelectOption('Publisher', 'publisher'),
  new SelectOption('Vendor', 'vendor'),
  new SelectOption('Agency', 'agency'),
  new SelectOption('Station / Local Office', 'station'),
  new SelectOption('Holding', 'holding'),
]

export const InvoicePaymentTerms: SelectOption[] = [
  new SelectOption('Net 0', 'net_0'),
  new SelectOption('Net 30', 'net_30'),
  new SelectOption('Net 60', 'net_60'),
  new SelectOption('Net 90', 'net_90'),
  new SelectOption('Net 120', 'net_120'),
]

export const InvoiceTemplates: SelectOption[] = [
  new SelectOption('Default', 'default'),
  new SelectOption('Havas Discount Template', 'discount'),
  new SelectOption('No Flight Dates', 'no_flight'),
  new SelectOption('OAREX Customer Template', 'oarex'),
  new SelectOption('Totals Only', 'totals'),
  new SelectOption('Beachfront', 'beachfront'),
  new SelectOption('3rd Party', 'third_party'),
  new SelectOption('3rd Party With ISCI', 'third_party_isci'),
  new SelectOption('Service Month', 'service_month'),
  new SelectOption('Buffalo Rock Agency', 'buffalo_rock_agency'),
  new SelectOption('Totals & Service Month', 'totals_service_month'),
]

export const AgencyFeeMode: SelectOption[] = [
  new SelectOption('Default', 'default'),
  new SelectOption('Minimum Guaranteed', 'minimum_guaranteed'),
]

export const AgencyFeeModel: SelectOption[] = [
  new SelectOption('None', 'none'),
  new SelectOption('Percentage', 'percentage'),
  new SelectOption('CPM', 'cpm'),
]

export const ExpenseCategories: SelectOption[] = [
  new SelectOption('Media Cost', 'media_cost'),
  new SelectOption('Media Cost SSL', 'media_cost_ssl'),
  new SelectOption('Media Cost CCL', 'media_cost_ccl'),
  new SelectOption('Ad Tech', 'ad_tech'),
  new SelectOption('Employees', 'employees'),
  new SelectOption('Commissions', 'commissions'),
  new SelectOption('Travel', 'travel'),
  new SelectOption('Others', 'others'),
]

export const PaymentMethods = [
  { text: 'ACH', value: 'ach' },
  { text: 'Cash', value: 'cash' },
  { text: 'Wire', value: 'wire' },
  { text: 'Check', value: 'check' },
  { text: 'Credit Card', value: 'credit_card' },
  { text: 'Credit', value: 'credit' },
  { text: 'Others', value: 'others' },
]

export const CommisionDeductible: SelectOption[] = SelectOption.createOptionsWithDescription([
  {
    name: 'No',
    value: 'no',
    description: 'Do not deduct this cost from sales reps commission',
  },
  {
    name: 'All Media Plans',
    value: 'media_plans',
    description: 'Deduct sales commission from all Media Plans',
  },
  {
    name: 'Media Ocean Plans',
    value: 'media_ocean_media_plans_only',
    description: 'Deduct sales commission from all Media Ocean Media Plans',
  },
  {
    name: 'Strata Plans',
    value: 'strata_media_plans_only',
    description: 'Deduct sales commission from all Strata Media Plans',
  },
  {
    name: 'Specific Media Plans',
    value: 'specific_media_plans',
    description: 'Deduct sales commission from specific Media Plans',
  },
])

export const CommisionDeductibleMode: any[] = [
  ...SelectOption.createOptionsWithDescription([
    {
      name: 'Absolute Value',
      value: 'absolute',
      description: 'Distribute cost proportionally based on Media Plan impressions',
    },
    {
      name: 'Fixed Percentage',
      value: 'percentage',
      description: 'Deduct a percentage of the Gross Cost from the sales reps commission',
    },
    {
      name: 'Percentage Cap',
      value: 'percentage_cap',
      description:
        'Deduct this cost from the sales reps commission up to a maximum percentage of the Media Plan Gross Cost',
    },
    {
      name: 'Percentage Minimum',
      value: 'percentage_min',
      description:
        'Deduct this cost from the sales reps commission with a minimum percentage of the Media Plan Gross Cost',
    },
  ]),
  {
    name: 'System Defined',
    value: 'system_defined',
    description: 'Automatically distribute cost based on system data',
    $isDisabled: true,
  },
]

export const CategoryAuthorityOptions: SelectOption[] = [new SelectOption('IAB', 'iab')]

export const CategoryAuthorityVersionOptions: any = {
  iab: [new SelectOption('1.0', '1.0')],
}

export const IndustryOptions: SelectOption[] = [
  new SelectOption('Advertising & Promotion', 'advertising_and_promotion'),
  new SelectOption('Agricultural Business', 'agricultural_business'),
  new SelectOption('Apparel & Accessories', 'apparel_and_accessories'),
  new SelectOption('Associations & Organizations', 'associations_and_organizations'),
  new SelectOption('Automotive', 'automotive'),
  new SelectOption('Beverages', 'beverages'),
  new SelectOption('Broadcasting', 'broadcasting'),
  new SelectOption('Commerce & Sales', 'commerce_and_sales'),
  new SelectOption('Consumer Electronics', 'consumer_electronics'),
  new SelectOption('Content & Experience', 'content_and_experience'),
  new SelectOption('Data', 'data'),
  new SelectOption('Digital Business Provider', 'digital_business_provider'),
  new SelectOption('Education', 'education'),
  new SelectOption('Energy, Gasoline & Oil', 'energy_gasoline_and_oil'),
  new SelectOption('Entertainment', 'entertainment'),
  new SelectOption('Financial Services', 'financial_services'),
  new SelectOption('Food', 'food'),
  new SelectOption('Furniture', 'furniture'),
  new SelectOption('Government', 'government'),
  new SelectOption('Health Care', 'health_care'),
  new SelectOption('Home Related', 'home_related'),
  new SelectOption('Management', 'management'),
  new SelectOption('Manufacturing', 'manufacturing'),
  new SelectOption('Office Related', 'office_related'),
  new SelectOption('Packaged Goods', 'packaged_goods'),
  new SelectOption('Publishing & Printed Media', 'publishing_and_printed_media'),
  new SelectOption('Real Estate', 'real_estate'),
  new SelectOption('Retail Stoers & Chains', 'retail_stoers_and_chains'),
  new SelectOption('Service Businesses', 'service_businesses'),
  new SelectOption('Social & Relationships', 'social_and_relationships'),
  new SelectOption('Sports & Recreation', 'sports_and_recreation'),
  new SelectOption('Technology', 'technology'),
  new SelectOption('Telecommunications', 'telecommunications'),
  new SelectOption('Textiles', 'textiles'),
  new SelectOption('Transportation & Shipping', 'transportation_and_shipping'),
  new SelectOption('Travel & Hospitality', 'travel_and_hospitality'),
]

export const SubIndustryOptions: any = {
  advertising_and_promotion: [
    new SelectOption('Display & Programmatic Advertising', 'display_and_programmatic_advertising'),
    new SelectOption('Advertising', 'advertising'),
    new SelectOption('Mobile Marketing', 'mobile_marketing'),
    new SelectOption('Native/Content', 'native_content'),
    new SelectOption('Advertising', 'advertising'),
    new SelectOption('Print', 'print'),
    new SelectOption('Public Relations', 'public_relations'),
    new SelectOption('Search & Social', 'search_and_social'),
    new SelectOption('Advertising', 'advertising'),
    new SelectOption('Video Advertising', 'video_advertising'),
  ],
  agricultural_business: [
    new SelectOption('Industrial & Farm Equipment', 'industrial_and_farm_equipment'),
    new SelectOption(
      'Misc. Services for Farmers & Growers',
      'misc_services_for_farmers_and_growers',
    ),
    new SelectOption('Seed, Feed & Fertilizer', 'seed_feed_and_fertilizer'),
    new SelectOption('Veterinary Medicine', 'veterinary_medicine'),
  ],
  apparel_and_accessories: [
    new SelectOption('Clothing Manufacturers', 'clothing_manufacturers'),
    new SelectOption(
      'Handbags, Sunglasses, Misc Accessories',
      'handbags_sunglasses_misc_accessories',
    ),
    new SelectOption('Infant & Youth Apparel', 'infant_and_youth_apparel'),
    new SelectOption('Jewelry & Watches', 'jewelry_and_watches'),
    new SelectOption('Luggage & Briefcases', 'luggage_and_briefcases'),
    new SelectOption('Retailers of Apparel & Accessories', 'retailers_of_apparel_and_accessories'),
    new SelectOption('Shoe Manufacturers', 'shoe_manufacturers'),
  ],
  associations_and_organizations: [
    new SelectOption('Charitable Organizations', 'charitable_organizations'),
    new SelectOption(
      'Foundations & Cultural Institutions',
      'foundations_and_cultural_institutions',
    ),
    new SelectOption('Non-Profit Organizations', 'non_profit_organizations'),
  ],
  automotive: [
    new SelectOption('OEM & Suppliers', 'oem_and_suppliers'),
    new SelectOption('Aftermarket, Tires, Parts', 'aftermarket_tires_parts'),
    new SelectOption('Auto Care', 'auto_care'),
    new SelectOption('Auto Manufacturers', 'auto_manufacturers'),
    new SelectOption('Buses & Other Vehicles', 'buses_and_other_vehicles'),
    new SelectOption('Dealership', 'dealership'),
    new SelectOption('Motorcycles', 'motorcycles'),
    new SelectOption('Tractor Trailer Manufacturers', 'tractor_trailer_manufacturers'),
  ],
  beverages: [
    new SelectOption('Beer, Wine, Liquor, Spirits', 'beer_wine_liquor_spirits'),
    new SelectOption('Non-Alcoholic', 'non_alcoholic'),
  ],
  broadcasting: [
    new SelectOption('Programming Content Providers', 'programming_content_providers'),
    new SelectOption(
      'Programming Transmission Service Providers',
      'programming_transmission_service_providers',
    ),
    new SelectOption('Sports News, Programming & Media', 'sports_news_programming_and_media'),
    new SelectOption('Television & Radio Stations', 'television_and_radio_stations'),
  ],
  commerce_and_sales: [
    new SelectOption('Affiliate Marketing & Management', 'affiliate_marketing_and_management'),
    new SelectOption('Channel, Partner & Local Marketing', 'channel_partner_and_local_marketing'),
    new SelectOption('Ecommerce Marketing', 'ecommerce_marketing'),
    new SelectOption('Ecommerce Platforms & Carts', 'ecommerce_platforms_and_carts'),
    new SelectOption('Retail, Proximity & IoT Marketing', 'retail_proximity_and_iot_marketing'),
    new SelectOption(
      'Sales Automation, Enablement & Intelligence',
      'sales_automation_enablement_and_intelligence',
    ),
  ],
  consumer_electronics: [
    new SelectOption('Electronic Gaming Systems', 'electronic_gaming_systems'),
    new SelectOption(
      'Personal Appliances: Shavers, Hair Dryers, etc.',
      'personal_appliances_shavers_hair_dryers_etc.',
    ),
    new SelectOption('Personal Computers & Organizers', 'personal_computers_and_organizers'),
    new SelectOption('Photography & Video Equipment', 'photography_and_video_equipment'),
    new SelectOption('Televisions, Steroes, DVDs, Blu-Ray', 'televisions_steroes_dvds_bluray'),
  ],
  content_and_experience: [
    new SelectOption(
      'Content Management System (CMS) & Web Experience Management',
      'content_management_system_and_web_experience_management',
    ),
    new SelectOption('Content Marketing', 'content_marketing'),
    new SelectOption('Email Marketing', 'email_marketing'),
    new SelectOption('Interactive Content', 'interactive_content'),
    new SelectOption(
      'Marketing Automation & Campaign/Lead Management',
      'marketing_automation_and_campaign_lead_management',
    ),
    new SelectOption('Marketing Resource Management (MRM)', 'marketing_resource_management'),
    new SelectOption('Mobile Apps', 'mobile_apps'),
    new SelectOption(
      'Optimization, Personalization & Testing',
      'optimization_personalization_and_testing',
    ),
    new SelectOption('Search Engine Optimization (SEO)', 'search_engine_optimization'),
    new SelectOption('Video Marketing', 'video_marketing'),
  ],
  data: [
    new SelectOption(
      'Audience/Marketing Data & Data Enhancement',
      'audience_marketing_data_and_data_enhancement',
    ),
    new SelectOption(
      'Business/Customer Intelligence & Data Science',
      'business_customer_intelligence_and_data_science',
    ),
    new SelectOption('Customer Data Platform', 'customer_data_platform'),
    new SelectOption('Dashboards & Data Visualization', 'dashboards_and_data_visualization'),
    new SelectOption('Data Management Platform (DMP)', 'data_management_platform'),
    new SelectOption(
      'Marketing Analytics, Performance & Attribution',
      'marketing_analytics_performance_and_attribution',
    ),
    new SelectOption('Mobile & Web Analytics', 'mobile_and_web_analytics'),
    new SelectOption('Predictive Analytics', 'predictive_analytics'),
    new SelectOption(
      'iPaaS, Cloud/Data Integration & Tag Management',
      'ipaas_cloud_data_integration_and_tag_management',
    ),
  ],
  digital_business_provider: [
    new SelectOption('Mobile App', 'mobile_app'),
    new SelectOption('Online Retailer', 'online_retailer'),
    new SelectOption('Social Media', 'social_media'),
    new SelectOption('Streaming Media', 'streaming_media'),
  ],
  education: [
    new SelectOption(
      'Child Care, Elementary & Secondary Schools',
      'child_care_elementary_and_secondary_schools',
    ),
    new SelectOption('College & Universities', 'college_and_universities'),
    new SelectOption('Education & Training Centers', 'education_and_training_centers'),
  ],
  energy_gasoline_and_oil: [
    new SelectOption('Alternative Energy', 'alternative_energy'),
    new SelectOption('Energy Distributors', 'energy_distributors'),
    new SelectOption('Gasoline & Motor Oil Marketers', 'gasoline_and_motor_oil_marketers'),
    new SelectOption(
      'Oil & Gas Exploration, Production & Equipment',
      'oil_and_gas_exploration_production_and_equipment',
    ),
    new SelectOption('Power Generation Equipment', 'power_generation_equipment'),
    new SelectOption('Utility Companies', 'utility_companies'),
  ],
  entertainment: [
    new SelectOption('Amusement Parks, Zoos, Attractions', 'amusement_parks_zoos_attractions'),
    new SelectOption(
      'Fine Arts: Museums, Theater, Ballet, Symphony',
      'fine_arts_museums_theater_ballet_symphony',
    ),
    new SelectOption(
      'Gambling: Lotteries, Casinos, Gambling Venues, Supplies & Equipment',
      'gambling_lotteries_casinos_gambling_venues_supplies_and_equipment',
    ),
    new SelectOption(
      'Movies: Production, Distribution, Theaters',
      'movies_production_distribution_theaters',
    ),
    new SelectOption(
      'Music: Record Labels, Music Publishers, Distributors',
      'music_record_labels_music_publishers_distributors',
    ),
    new SelectOption('Musical Instruments', 'musical_instruments'),
    new SelectOption(
      'Toys, Games, Hobbies, Arts & Crafts Supplies',
      'toys_games_hobbies_arts_and_crafts_supplies',
    ),
  ],
  financial_services: [
    new SelectOption('Banking', 'banking'),
    new SelectOption('Brokerage & Investment Services', 'brokerage_and_investment_services'),
    new SelectOption('Credit Card', 'credit_card'),
    new SelectOption('Credit Services', 'credit_services'),
    new SelectOption('Equity Investment Firm', 'equity_investment_firm'),
    new SelectOption('Insurance', 'insurance'),
    new SelectOption('Leasing', 'leasing'),
    new SelectOption(
      'Mortgage Companies & Commercial Lenders',
      'mortgage_companies_and_commercial_lenders',
    ),
    new SelectOption('Venture Capital', 'venture_capital'),
  ],
  food: [
    new SelectOption(
      'Food Growers, Wholesalers & Distributors',
      'food_growers_wholesalers_and_distributors',
    ),
    new SelectOption('Food Service Equipment & Supplies', 'food_service_equipment_and_supplies'),
    new SelectOption('Grocery Stores', 'grocery_stores'),
    new SelectOption('Packaged Foods', 'packaged_foods'),
    new SelectOption('Restaurants', 'restaurants'),
  ],
  furniture: [new SelectOption('Office', 'office'), new SelectOption('Residential', 'residential')],
  government: [
    new SelectOption('Anti-Drug & Anti-Tobacco', 'anti_drug_and_anti_tobacco'),
    new SelectOption('Armed Forces', 'armed_forces'),
    new SelectOption('Health Organizations', 'health_organizations'),
    new SelectOption('Metropolitan Mass Transit', 'metropolitan_mass_transit'),
    new SelectOption('Other Governmental', 'other_governmental'),
    new SelectOption('State Lotteries', 'state_lotteries'),
    new SelectOption('Tourism Boards', 'tourism_boards'),
  ],
  health_care: [
    new SelectOption(
      'Ambulance Services, Emergency Transportation',
      'ambulance_services_emergency_transportation',
    ),
    new SelectOption('Biotechnology', 'biotechnology'),
    new SelectOption('Eye Care, Optical', 'eye_care_optical'),
    new SelectOption('Health Care Plans', 'health_care_plans'),
    new SelectOption(
      'Home Health Care Services & Products',
      'home_health_care_services_and_products',
    ),
    new SelectOption(
      'Hospitals, Nursing Homes, Health Care Facilities',
      'hospitals_nursing_homes_health_care_facilities',
    ),
    new SelectOption('Medical Devices & Equipment', 'medical_devices_and_equipment'),
    new SelectOption('Medical Laboratories', 'medical_laboratories'),
    new SelectOption('Medical Practice Management', 'medical_practice_management'),
    new SelectOption('Pharmaceuticals & OTC drugs', 'pharmaceuticals_and_otc_drugs'),
    new SelectOption('Surgical Supplies & Equipment', 'surgical_supplies_and_equipment'),
    new SelectOption('Vitamins & Nutritional Supplements', 'vitamins_and_nutritional_supplements'),
  ],
  home_related: [
    new SelectOption('Appliances for the Home', 'appliances_for_the_home'),
    new SelectOption('Building Materials & Supplies', 'building_materials_and_supplies'),
    new SelectOption('Floor, Wall & Window Covering', 'floor_wall_and_window_covering'),
    new SelectOption('Hand Tools & Accessories', 'hand_tools_and_accessories'),
    new SelectOption('Home Services: Pests, Maid, Security', 'home_services_pests_maid_security'),
    new SelectOption('Housewares, Kitchenware & Utensils', 'housewares_kitchenware_and_utensils'),
    new SelectOption(
      'Lamps, Decorative Hardware & Other Home Accessories',
      'lamps_decorative_hardware_and_other_home_accessories',
    ),
    new SelectOption('Lawn & Garden, Outdoor Living', 'lawn_and_garden_outdoor_living'),
    new SelectOption(
      'Plumbing & HVAC Equipment & Services',
      'plumbing_and_hvac_equipment_and_services',
    ),
    new SelectOption(
      'Swimming Pool & Hot Tubs: Equipment & Chemicals',
      'swimming_pool_and_hot_tubs_equipment_and_chemicals',
    ),
  ],
  management: [
    new SelectOption('Agile & Lean Management', 'agile_and_lean_management'),
    new SelectOption('Budgeting & Finance', 'budgeting_and_finance'),
    new SelectOption('Collaboration', 'collaboration'),
    new SelectOption('Product Management', 'product_management'),
    new SelectOption('Projects & Workflow', 'projects_and_workflow'),
    new SelectOption('Talent Management', 'talent_management'),
    new SelectOption('Vendor Analysis', 'vendor_analysis'),
  ],
  manufacturing: [
    new SelectOption('Chemicals', 'chemicals'),
    new SelectOption(
      'Forestry, Lumber & Paper Products Manufacturers',
      'forestry_lumber_and_paper_products_manufacturers',
    ),
    new SelectOption('Industrial Equipment & Machinery', 'industrial_equipment_and_machinery'),
    new SelectOption('Machine Parts', 'machine_parts'),
    new SelectOption('Metals & Mining', 'metals_and_mining'),
    new SelectOption('Rubber, Plastics and Other Raw Goods', 'rubber_plastics_and_other_raw_goods'),
  ],
  office_related: [
    new SelectOption('Business Machines & Equipment', 'business_machines_and_equipment'),
    new SelectOption(
      'Fine Writing Instruments & Stationery',
      'fine_writing_instruments_and_stationery',
    ),
    new SelectOption('Office Furniture', 'office_furniture'),
    new SelectOption('Office Supplies', 'office_supplies'),
  ],
  packaged_goods: [
    new SelectOption(
      'Cosmetics, Perfume, Toiletries & Personal Care Items',
      'cosmetics_perfume_toiletries_and_personal_care_items',
    ),
    new SelectOption('Fds & Bvrgs', 'fds_and_bvrgs'),
    new SelectOption('Film, Batteries & Flashlights', 'film_batteries_and_flashlights'),
    new SelectOption('Household Cleaners & Products', 'household_cleaners_and_products'),
    new SelectOption(
      'Infant & Youth Supplies & Equipment',
      'infant_and_youth_supplies_and_equipment',
    ),
    new SelectOption('Lunch Boxes, Thermoses, Coolers', 'lunch_boxes_thermoses_coolers'),
    new SelectOption('Other Packaged Goods', 'other_packaged_goods'),
    new SelectOption('Pet Supplies', 'pet_supplies'),
    new SelectOption('Tobacco', 'tobacco'),
  ],
  publishing_and_printed_media: [
    new SelectOption('Books', 'books'),
    new SelectOption('Magazines', 'magazines'),
    new SelectOption('Newspapers', 'newspapers'),
    new SelectOption('Other Periodicals & Print Media', 'other_periodicals_and_print_media'),
  ],
  real_estate: [
    new SelectOption('REITs', 'reits'),
    new SelectOption('Architects & Engineers', 'architects_and_engineers'),
    new SelectOption('Construction Firms', 'construction_firms'),
    new SelectOption(
      'Manufactured Homes, Mobile Homes, Trailer Parks',
      'manufactured_homes_mobile_homes_trailer_parks',
    ),
    new SelectOption('Parking Companies', 'parking_companies'),
    new SelectOption(
      'Property Management, Maintenance & Related Services',
      'property_management_maintenance_and_related_services',
    ),
    new SelectOption('Real Estate Developers', 'real_estate_developers'),
  ],
  retail_stoers_and_chains: [
    new SelectOption('Convenience Stores & Gas Stations', 'convenience_stores_and_gas_stations'),
    new SelectOption('Department Stores', 'department_stores'),
    new SelectOption('Drug Stores', 'drug_stores'),
    new SelectOption('Grocery Stores', 'grocery_stores'),
    new SelectOption(
      'Home Improvement, Hardware & Home Decor Retailers',
      'home_improvement_hardware_and_home_decor_retailers',
    ),
    new SelectOption('Mass Retailers', 'mass_retailers'),
    new SelectOption('Retailers of Apparel & Accessories', 'retailers_of_apparel_and_accessories'),
    new SelectOption('Specialty Retailers', 'specialty_retailers'),
  ],
  service_businesses: [
    new SelectOption('Accounting, Consulting, Law Firms', 'accounting_consulting_law_firms'),
    new SelectOption(
      'Building Maintenance & Related Services',
      'building_maintenance_and_related_services',
    ),
    new SelectOption('Environmental Services', 'environmental_services'),
    new SelectOption('Funerary Services', 'funerary_services'),
    new SelectOption(
      'Miscellaneous Rentals: Tools, Party Supplies, Linens, Uniforms, etc.',
      'miscellaneous_rentals_tools_party_supplies_linens_uniforms_etc.',
    ),
    new SelectOption('Printing & Packaging', 'printing_and_packaging'),
    new SelectOption('Security, Pest Control, Janitorial', 'security_pest_control_janitorial'),
    new SelectOption(
      'Staffing, Temporary Employment, HR & Benefits Services',
      'staffing_temporary_employment_hr_and_benefits_services',
    ),
    new SelectOption('Waste Management, Recycling', 'waste_management_recycling'),
  ],
  social_and_relationships: [
    new SelectOption('Account Based Marketing (ABM)', 'account_based_marketing'),
    new SelectOption('Advocacy, Loyalty & Referrals', 'advocacy_loyalty_and_referrals'),
    new SelectOption('Call Analytics & Management', 'call_analytics_and_management'),
    new SelectOption('Community & Reviews', 'community_and_reviews'),
    new SelectOption(
      'Customer Experience, Service & Success',
      'customer_experience_service_and_success',
    ),
    new SelectOption('Customer Relationship Management (CRM)', 'customer_relationship_management'),
    new SelectOption('Events, Meetings & Webinars', 'events_meetings_and_webinars'),
    new SelectOption('Feedback & Chat', 'feedback_and_chat'),
    new SelectOption('Influencers', 'influencers'),
    new SelectOption(
      'Social Media Marketing & Monitoring',
      'social_media_marketing_and_monitoring',
    ),
  ],
  sports_and_recreation: [
    new SelectOption('Firearms', 'firearms'),
    new SelectOption(
      'Health & Fitness Clubs & Equipment',
      'health_and_fitness_clubs_and_equipment',
    ),
    new SelectOption(
      'Other Recreational Equipment: Pool Tables, Jungle Gym',
      'other_recreational_equipment_pool_tables_jungle_gym',
    ),
    new SelectOption('Pleasure Boats, Watercraft', 'pleasure_boats_watercraft'),
    new SelectOption(
      'Recreational Vehicles: ATVs, Snowmobiles, RVs, etc',
      'recreational_vehicles_atvs_snowmobiles_rvs_etc',
    ),
    new SelectOption('Sporting Goods & Equipment', 'sporting_goods_and_equipment'),
    new SelectOption('Sports Teams & Leagues', 'sports_teams_and_leagues'),
  ],
  technology: [
    new SelectOption('Aerospace & Defense', 'aerospace_and_defense'),
    new SelectOption(
      'Computer Hardware: Components & Parts',
      'computer_hardware_components_and_parts',
    ),
    new SelectOption(
      'Computer Hardware: Systems, Peripherals, PCs, Laptops',
      'computer_hardware_systems_peripherals_pcs_laptops',
    ),
    new SelectOption('Electrical Components & Devices', 'electrical_components_and_devices'),
    new SelectOption(
      'Services: IT Consulting, Systems Integration, IT Training',
      'services_it_consulting_systems_integration_it_training',
    ),
    new SelectOption('Software', 'software'),
  ],
  telecommunications: [
    new SelectOption(
      'Cellular Phones & Wireless Service Providers',
      'cellular_phones_and_wireless_service_providers',
    ),
    new SelectOption(
      'Communications Equipment, Satellites & Transmission Services',
      'communications_equipment_satellites_and_transmission_services',
    ),
    new SelectOption(
      'Internet & Broadband Service Providers',
      'internet_and_broadband_service_providers',
    ),
    new SelectOption('Internet Content Providers', 'internet_content_providers'),
    new SelectOption(
      'Internet Search Engines & Reference Guide Providers',
      'internet_search_engines_and_reference_guide_providers',
    ),
    new SelectOption(
      'Telephone Transmission Service & Equipment',
      'telephone_transmission_service_and_equipment',
    ),
  ],
  textiles: [
    new SelectOption('Carpet & Floor Covering', 'carpet_and_floor_covering'),
    new SelectOption('Fabric', 'fabric'),
    new SelectOption('Fibers & Yarn', 'fibers_and_yarn'),
    new SelectOption('Textile Machinery & Supplies', 'textile_machinery_and_supplies'),
    new SelectOption('Wall Covering', 'wall_covering'),
  ],
  transportation_and_shipping: [
    new SelectOption('Freight Delivery Services', 'freight_delivery_services'),
    new SelectOption('Logistics Service Providers', 'logistics_service_providers'),
    new SelectOption(
      'Metropolitan Mass Transit Authorities',
      'metropolitan_mass_transit_authorities',
    ),
    new SelectOption('Parcel Delivery Services', 'parcel_delivery_services'),
    new SelectOption('Transportation Equipment', 'transportation_equipment'),
    new SelectOption('Truck Renting & Leasing', 'truck_renting_and_leasing'),
  ],
  travel_and_hospitality: [
    new SelectOption('Airlines', 'airlines'),
    new SelectOption('Bus Travel & Train Travel', 'bus_travel_and_train_travel'),
    new SelectOption('Car Rental', 'car_rental'),
    new SelectOption('Casino Hotels', 'casino_hotels'),
    new SelectOption('Cruise Lines', 'cruise_lines'),
    new SelectOption('Hotels, Motels & Resorts', 'hotels_motels_and_resorts'),
    new SelectOption(
      'Taxi, Limousine, Private Coach & Other Passenger Services',
      'taxi_limousine_private_coach_and_other_passenger_services',
    ),
    new SelectOption(
      'Vacation & Travel Planners, Tour Operators',
      'vacation_and_travel_planners_tour_operators',
    ),
  ],
}

export const RegionOptions: SelectOption[] = [
  new SelectOption('East', 'east'),
  new SelectOption('Midwest', 'midwest'),
  new SelectOption('West', 'west'),
  new SelectOption('National', 'national'),
]

export const BillingCycle: SelectOption[] = [
  new SelectOption('Calendar Monthly', 'monthly'),
  new SelectOption('Broadcast Month', 'broadcast_month'),
]
export const RegionGroupMap: any = {
  west: '9ae9374c-55d6-48c0-bd1e-86eaabb49f9b',
  east: '9ae937aa-7112-4478-b9d3-95115f6ad77e',
  national_both: '9ae97aaf-c145-47c5-a8ce-e808da59c36f',
  national_ccl: '9ae97a25-3221-4dc9-9378-d585ab970d5c',
  national: '9ae97a25-3221-4dc9-9378-d585ab970d5c',
  national_ssl: '9ae95f35-6e9f-4848-b210-ff405bbf7143',
  midwest: '9ae937f0-5568-41f8-bd0e-473a439d8aef',
  marketing_material: '9ae93836-9bf1-43f0-bf39-41599f7cf616',
}

export const OptionalOpportunityAgencyIds = [
  '8e3e40d3-d1b5-484e-a56e-22cab7386941', // Compulse
  '90ba30fd-5db9-4883-80d5-25ab3df70a41', // Locality Streaming LLC
  '911e85d2-a1c8-4fb0-bdb4-3356052d92a1', // Foxhole Advisors
  '9a68bea7-6eff-494d-b902-00385c12e0d3', // Firebrand Sports Media
  'aa63ecfe-a011-11ee-a2a8-060388677220', // Brkthru Digital
]

export const LostCategories: SelectOption[] = [
  new SelectOption('Buys Programmatically', 'buys_programmatically'),
  new SelectOption('Lost to a Competitor', 'lost_to_competitor'),
  new SelectOption('Cost to expensive', 'cost_to_expensive'),
  new SelectOption('No Budget', 'no_budget'),
  new SelectOption('Other', 'other'),
  new SelectOption('Purchased Linear', 'purchased_linear'),
  new SelectOption('Closed Another Media Plan', 'closed_another_media_plan'),
]

export const TASK_REQUEST_OPTION_TYPE: SelectOption[] = [
  new SelectOption('Media Plan', 'media_plan'),
  new SelectOption('Deck', 'deck'),
  new SelectOption('Max Avails', 'max_avail'), // TODO if we remove the S it will throw error check more later
  new SelectOption('Marketing Sheet / One Sheet', 'marketing_sheet'),
]

export const RemoteConnections: SelectOption[] = [
  new SelectOption('Cast Iron Media (GAM)', 'gam_cim'),
  new SelectOption('MLB (GAM)', 'gam_mlb'),
  new SelectOption('Minor League Baseball (GAM)', 'gam_milb'),
  new SelectOption('SNY (Freewheel)', 'freewheel_sny'),
  new SelectOption('FW TEST', 'sny_freewheel_stag'),
]

export const capitalize = (text: string) => {
  const words = text.split(' ')

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1)
  }

  return words.join(' ')
}

export const randomUUID = (): string =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0
    const v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })

export const countPlaces = (num: number) => {
  let text = num.toString()
  let index = text.indexOf('.')
  return index == -1 ? 0 : text.length - index - 1
}

export const parseHtmlTable = (text: string) => {
  let match = text.match(/<tr.*?>(.*?)<\/tr>/gims)
  if (match) {
    let data = match
      .map(row => {
        let cells = row.match(/<td.*?>(.*?)<\/td>/gims)
        if (cells) {
          return cells.map(cell => cell.replace(/<.*?>/gims, ''))
        }
        return []
      })
      .filter(row => row.length > 0)
    const headers: any = data.shift()?.map((value: string) => snakeCase(value))

    return data.map(row => {
      let ret: any = {}
      row.forEach((cell, index) => {
        ret[headers[index]] = cell
      })
      return ret
    })
  }

  return []
}

export const cdnPath = process.env.VUE_APP_CDN_PATH

export const buildFormData = (formData: FormData, data: any, parentKey: string = '') => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
    })
  } else {
    const value = data == null ? '' : data

    formData.append(parentKey, value)
  }
}

export const sysEnv = (section: string, type: string, key: string) =>
  // @ts-ignore
  getModule(SystemtModule).env_vars[section][type][key]

/**
 * Check if campaign is in date range
 * @param range_start_at string - start date of range
 * @param range_end_at string - end date of range
 * @param campaign_start_at string - start date of campaign
 * @param campaign_end_at string - end date of campaign
 * @returns boolean
 */
export const isCampagingInDateRange = (
  range_start_at: string,
  range_end_at: string,
  campaign_start_at: string,
  campaign_end_at: string,
): boolean =>
  moment(campaign_start_at).isBetween(range_start_at, range_end_at, null, '[]')
  || moment(campaign_end_at).isBetween(range_start_at, range_end_at, null, '[]')
  || moment(range_start_at).isBetween(campaign_start_at, campaign_end_at, null, '[]')
  || moment(range_end_at).isBetween(campaign_start_at, campaign_end_at, null, '[]')

export const toDateTime = (date: string): DateTime => {
  if (date.includes('T')) {
    return DateTime.fromISO(date).setZone(process.env.VUE_APP_TIMEZONE)
  }
  if (date.includes(':')) {
    return DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss')
  }
  return DateTime.fromFormat(date, 'yyyy-MM-dd')
}

export const DATE_TIME_FORMAT = 'yyyy-LL-dd HH:mm:ss'

export const DATE_FORMAT = 'yyyy-LL-dd'

export const HUMAN_DATE_FORMAT = 'LLL dd, yyyy'

export const HUMAN_DATE_TIME_FORMAT = 'LLL dd, yyyy HH:mm:ss'
